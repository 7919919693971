import React, { useEffect, useState } from "react";

export default function StreamCaps() {
  const [alerts, setAlerts] = useState([]);

  const fetchAlerts = async () => {
    try {
      const response = await fetch(
        "https://www.apiofdreams.com/v1/streamcaps/alerts"
      );
      const data = await response.json();
      if (data.alerts) {
        setAlerts(data.alerts);
      }
    } catch (error) {
      console.error("Error fetching alerts:", error);
    }
  };

  useEffect(() => {
    fetchAlerts();
  }, []);

  return (
    <div>
      <h1>StreamCaps</h1>
      <div className="alerts-container">
        {alerts.map((alert) => (
          <div key={alert._id} className="alert">
            {alert.alert}
          </div>
        ))}
      </div>
    </div>
  );
}
